export class EmailBroadcastRequest {
    convoId: string;
    emailSubjectType: string;
    serviceEmailCustomSubjectLine: string;
    enableFollowUp: boolean;
    followUpAfterDays?: string;
    followUpEmailSubjectType?: string;
    followUpCustomSubjectLine?: string;
    audienceId?: string;
    channelCode?: string;
    isTest: boolean;
}
