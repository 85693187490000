import { EmailSubjectType } from '@/enums';

export default [
    {
        id: EmailSubjectType.ConvoName,
        value: 'Convo Title',
    },
    {
        id: EmailSubjectType.ReminderConvoName,
        value: 'Reminder: Convo Title',
    },
    {
        id: EmailSubjectType.ReminderChannelName,
        value: `Don't forget you have an unread {Channel} chat`,
    },
    {
        id: EmailSubjectType.Custom,
        value: 'Custom Subject Line',
    },
];
