<template functional>
    <div class="text-preview">
        <p class="text-preview-label">{{ props.label }}</p>

        <div class="text-preview-content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextPreview',
    props: {
        label: {
            type: String,
            default: 'Preview',
        },
    },
};
</script>

<style scoped lang="scss">
.text-preview {
    padding: 6px 10px 18px;
    background-color: $color-near-white;
}

.text-preview-label {
    font-size: $font-size-xs;
    line-height: 1.5;
    color: $color-regent-gray;
}

.text-preview-content {
    font-size: $font-size-sm;
    line-height: 1.29;
    color: $color-oxford-blue;
    min-height: 18px;
}
</style>
