<template>
    <section>
        <div v-if="hasWarnings" class="m-b-2">
            <WarningMessage class="warning" :message="warningMessage" />
        </div>
        <p class="paragraph--medium">
            Send an email notification to subscribers of this channel. To change the language of the
            email, please
            <router-link
                :to="{ name: 'channel-detail', hash: '#email-variables' }"
                class="underline"
            >
                update the channel locale
            </router-link>
            .
        </p>

        <div class="align-center">
            <ToggleText
                id="email-broadcast"
                class="m-b-2"
                text-off="All"
                text-on="Audience"
                icon-off="publish"
                icon-on="audience-builder"
                :toggle-height="44"
                :toggle-width="360"
                :show-icons="true"
                :value="showAudienceList"
                @input="handleToggle"
            />
        </div>

        <div v-if="showAudienceList" class="broadcast__audience m-b-2">
            <h3 class="m-b-1">Audience</h3>

            <p class="m-b-2">Select the audience you would like to broadcast to</p>

            <AutoComplete
                :items="audienceAutoCompleteItems"
                label="Audience"
                placeholder="Search for an audience"
                @input="handleSelectAudience"
                @query="$emit('searchAudience', $event)"
            />
        </div>

        <div class="m-b-2">
            <h3 class="m-b-2">Email</h3>

            <Select
                v-model="campaignData.emailSubjectType"
                class="m-b-2"
                label="Email Subject"
                placeholder="Select email subject"
                :options="$options.subjectOptions"
            />

            <CustomSubjectLine
                v-if="showEmailSubjectInput"
                id="email-custom-subject-line"
                v-model="subjectModel"
                :variable-list="$options.variableList"
                :variable-values="$options.variableValues"
                placeholder="Enter email subject line"
                label="Custom Subject Line"
                :info-text="infoText"
                :max-chars="100"
                @interpolated="campaignData.emailCustomSubjectLine = $event"
            />
        </div>

        <div v-if="customReminderEmailEnabled" class="follow-up m-b-2">
            <h3 class="m-b-1">Follow-Up</h3>

            <Toggle
                id="follow-up-toggle"
                v-model="campaignData.followUpEnabled"
                label="Schedule a follow-up to encourage users who didn't engage with the first broadcast"
                class="m-b-2"
            >
            </Toggle>

            <div v-if="campaignData.followUpEnabled" class="follow-up__dropdowns">
                <Select
                    v-model="campaignData.followUpInterval"
                    class="select-interval"
                    label="Send Follow-Up After"
                    :options="$options.followUpOptions"
                    :has-margin-bottom="false"
                />

                <Select
                    v-model="campaignData.followUpEmailSubjectType"
                    class="select-subject"
                    label="Follow-Up Subject"
                    :has-margin-bottom="false"
                    :options="$options.followUpSubjectOptions"
                />
            </div>

            <div
                v-if="campaignData.followUpEnabled && showFollowUpSubjectInput"
                class="follow-up__custom-subject m-t-2"
            >
                <CustomSubjectLine
                    id="follow-up-custom-subject-line"
                    v-model="followUpSubjectModel"
                    :variable-list="$options.variableList"
                    :variable-values="$options.variableValues"
                    placeholder="Enter follow up email subject line"
                    label="Custom Subject Line"
                    :info-text="infoText"
                    :max-chars="100"
                    @interpolated="campaignData.followUpEmailCustomSubjectLine = $event"
                />
            </div>
        </div>
        <div class="text-center">
            <Button
                button-style="primary"
                has-border
                size="small"
                class="broadcast__button m-r-1"
                :disabled="!canSendBroadcast || hasWarnings"
                @click="handleTestBroadcast"
            >
                Test Campaign
            </Button>

            <Button
                button-style="primary"
                has-border
                size="small"
                class="broadcast__button"
                :disabled="!canSendBroadcast || hasWarnings"
                @click="handleSendBroadcast"
            >
                Send Campaign
            </Button>
        </div>
    </section>
</template>

<script>
import { EmailSubjectType } from '@/enums';
import CustomSubjectLine from '@/components/forms/CustomSubjectLine';
import followUpSubjectOptions from '@/json/convoServiceReminderEmailSubjectOptions';
import { ToggleText, AutoComplete, Select, Toggle } from '@/components/forms';
import subjectOptions from '@/json/convoServiceEmailSubjectOptions';
import followUpOptions from '@/json/followUpEmailDuration';
import Button from '@/components/ui/Button';
import config from '@/config';
import WarningMessage from '@/components/forms/partials/WarningMessage';

const variableList = [
    {
        name: 'Variables',
        items: [
            {
                key: 'convo-name',
                displayText: 'Convo Name',
                value: '{{convoName}}',
            },
            {
                key: 'channel-name',
                displayText: 'Channel Name',
                value: '{{channelName}}',
            },
        ],
    },
];

const defaultState = () => ({
    showAudienceList: false,
    infoText: 'Type {{ to enter Convo or Channel Name variables',
    subjectModel: '',
    followUpSubjectModel: '',
    campaignData: {
        selectedAudience: null,
        followUpEnabled: true,
        followUpInterval: 1,
        followUpEmailSubjectType: EmailSubjectType.ConvoName,
        followUpEmailCustomSubjectLine: '',
        emailSubjectType: EmailSubjectType.ConvoName,
        emailCustomSubjectLine: '',
    },
});

export default {
    name: 'EmailBroadcast',
    components: {
        CustomSubjectLine,
        AutoComplete,
        ToggleText,
        Button,
        Toggle,
        Select,
        WarningMessage,
    },
    props: {
        convo: {
            type: Object,
            required: true,
        },
        channel: {
            type: Object,
            required: true,
        },
        audiences: {
            type: Array,
            default: () => [],
        },
    },
    data: () => defaultState(),
    computed: {
        audienceAutoCompleteItems() {
            return this.audiences.map((audience) => audience.text);
        },
        canSendBroadcast() {
            return !!this.campaignData.emailSubjectType;
        },
        customReminderEmailEnabled() {
            return config.featureToggle.customReminderEmail;
        },
        showFollowUpSubjectInput() {
            return this.campaignData.followUpEmailSubjectType === EmailSubjectType.Custom;
        },
        showEmailSubjectInput() {
            return this.campaignData.emailSubjectType === EmailSubjectType.Custom;
        },
        hasWarnings() {
            return Object.keys(this.convo.warnings || {}).length > 0;
        },
        warningMessage() {
            const keys = Object.keys(this.convo.warnings || {});
            let message = '';
            if (keys.length > 0) {
                message = this.convo.warnings[keys[0]][0];
            }
            return message;
        },
    },
    created() {
        this.$options.variableList = variableList;
        this.$options.variableValues = this.getVariableValues();
        this.$options.subjectOptions = subjectOptions;
        this.$options.followUpOptions = followUpOptions;
        this.$options.followUpSubjectOptions = followUpSubjectOptions;
    },
    methods: {
        getVariableValues() {
            const obj = {};
            obj.channelName = this.channel.name;
            obj.convoName = this.convo.name;
            return obj;
        },
        handleToggle(value) {
            this.campaignData.selectedAudience = null;
            this.showAudienceList = value;
        },
        handleSelectAudience(audienceName, audienceIndex) {
            const audience = this.audiences[audienceIndex];
            if (audience) {
                this.campaignData.selectedAudience = audience;
            }
        },
        handleSendBroadcast() {
            if (!this.canSendBroadcast) {
                return;
            }
            this.$emit('sendBroadcast', this.campaignData);
        },
        handleTestBroadcast() {
            if (!this.canSendBroadcast) {
                return;
            }
            this.$emit('testBroadcast', this.campaignData);
        },
        resetState() {
            Object.assign(this.$data, defaultState());
        },
    },
};
</script>

<style scoped lang="scss">
h3 {
    font-size: 18px;
}

.follow-up {
    &__dropdowns {
        display: flex;
    }
    .select-interval {
        width: 30%;
        margin-right: 22px;
    }
    .select-subject {
        width: 70%;
    }
}

.warning {
    z-index: 1000;
    font-size: 14px;
}
</style>
