export class UrlBuilderService {
    url: URL;

    public setUrl(url: string) {
        this.url = new URL(url);
        return this;
    }

    public setParam(name: string, value: string) {
        if (!this.url) {
            throw new Error('Url must be set');
        }
        this.url.searchParams.set(name, value);
        return this;
    }

    public build() {
        if (!this.url) {
            throw new Error('Url must be set');
        }
        return this.url.toString().replace(/%7B/g, '{').replace(/%7D/g, '}');
    }
}
