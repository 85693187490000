<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <Spinner v-if="pageIsLoading" name="branded" class="publishing-spinner" :size="35" />

            <template v-if="!pageIsLoading">
                <PageHeading :breadcrumbs="breadcrumbs" title="Publish Summary" />

                <div class="publish-page-content p-t-2">
                    <section class="section-container section-container--2-columns">
                        <Panel title="Publish">
                            <p class="paragraph--medium">
                                Would you like this convo to be published? <br />
                                <em>Only Published Convos are accessible to users.</em>
                            </p>

                            <div class="align-center m-b-2">
                                <ToggleText
                                    id="publish"
                                    text-on="Publish"
                                    text-off="Unpublish"
                                    icon-on="publish"
                                    icon-off="unpublish"
                                    :show-icons="true"
                                    :toggle-height="44"
                                    :toggle-width="360"
                                    :value="publishing.publish"
                                    @input="
                                        (value) => setPublishingProperty({ key: 'publish', value })
                                    "
                                />
                            </div>

                            <p v-if="convoDetailsView.convo.lastPublishedVersion">
                                <em>There's a previously published version.</em>
                            </p>
                            <p>
                                <em>UNPUBLISH: Convo will <strong>not</strong> be scheduled.</em>
                            </p>
                        </Panel>

                        <Panel title="Push Notification" disabled>
                            <p class="paragraph--medium">Send a push notification</p>

                            <!-- TODO: Add push notification functionality -->
                        </Panel>

                        <Panel title="Discovery">
                            <ContentDiscovery v-model="discovery" :errors="publishing.errors" />
                        </Panel>

                        <Panel
                            title="Email Broadcast"
                            class="broadcast"
                            :disabled="disableEmailBroadcast"
                        >
                            <EmailBroadcast
                                :audiences="audiences"
                                :convo="convo"
                                :channel="channelDetails.channel"
                                @searchAudience="getAudiences"
                                @sendBroadcast="confirmSendBroadcast"
                                @testBroadcast="handleTestBroadcast"
                            />
                        </Panel>
                    </section>

                    <section class="section-container section-container--2-columns">
                        <ConvoUrl
                            :url="convoUrl || convoBaseUrl"
                            :show-configure-button="true"
                            @configure="showConvoUrlOverlay = true"
                        />

                        <Panel title="Chat Widget">
                            <WidgetSnippet
                                v-if="widgetSnippet"
                                :snippet="widgetSnippet"
                                :show-unread-badge="showUnreadBadge"
                                :show-chat-headline="showChatHeadline"
                                @unreadChatToggle="unreadBadge = $event"
                                @floatingHeadlineToggle="chatHeadline = $event"
                            />
                        </Panel>
                    </section>
                </div>
            </template>
        </div>

        <Stepper
            show-cancel-button
            show-add-button
            cancel-button-text="Back, Continue Editing"
            add-button-text="Publish & Finish"
            :add-button-is-disabled="pageIsLoading || $wait.is(LoadingFlag.ConvoPublish)"
            :is-loading="$wait.is(LoadingFlag.ConvoPublish)"
            @addButtonOnClick="addButtonOnClick"
            @cancelButtonOnClick="backButtonClick"
        />

        <ConvoUrlOverlay
            v-if="!pageIsLoading"
            :base-url="convoBaseUrl"
            :show-overlay="showConvoUrlOverlay"
            @changed="(url) => (convoUrl = url)"
            @close="showConvoUrlOverlay = false"
        />

        <Dialog
            show-confirm-button
            :is-dialog-visible="showBroadcastDialog"
            :is-loading="isBroadcasting"
            :confirm-button-text="'Yes, send'"
            @onClose="closeBroadcastDialog"
            @onClickOutside="closeBroadcastDialog"
            @closeOnEscapeEvent="closeBroadcastDialog"
            @confirmButtonOnClick="confirmBroadcastDialog"
        >
            <template #header>
                Sending email broadcast to
                {{
                    broadcastData && broadcastData.audience
                        ? broadcastData.audience.text
                        : 'all channel'
                }}
                members
            </template>
            <template #body> Are you sure you want to send this broadcast? </template>
        </Dialog>
        <Dialog
            close-button-text="Cancel"
            :is-dialog-visible="showCommentsRatingError"
            :show-confirm-button="false"
            @onClose="showCommentsRatingError = false"
            @onClickOutside="showCommentsRatingError = false"
            @closeOnEscapeEvent="showCommentsRatingError = false"
        >
            <template #header>
                <ErrorMessage size="large">Incomplete comments rating fields</ErrorMessage>
            </template>
            <template #body>
                <p
                    v-for="(item, key) in convoDetailsView.convo.errors.commentsRating"
                    :key="key"
                    class="m-b-1"
                    v-html="item"
                ></p>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { GetAudienceRequest } from '@/store/models/dtos/audienceRequests.dto';
import { EmailBroadcastFactory } from '@/store/models/factories/emailBroadcast.factory';
import EmailBroadcast from '@/components/ui/EmailBroadcast';
import ConvoUrlOverlay from '@/components/ConvoUrl/ConvoUrlOverlay';
import ContentDiscovery from '@/components/ui/ContentDiscovery';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import { ConvoOperation } from '@/enums';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import { ToggleText } from '@/components/forms';
import PageHeading from '@/components/ui/PageHeading';
import ConvoUrl from '@/components/ConvoUrl/ConvoUrl';
import getWidgetSnippet from '@/json/widgetSnippet';
import { AudienceClient } from '@/api/audience';
import Spinner from '@/components/ui/Spinner';
import { mapGetters, mapActions, mapState } from 'vuex';
import Stepper from '@/components/ui/Stepper';
import Dialog from '@/components/ui/Dialog';
import ErrorMessage from '@/components/ui/ErrorMessage';
import convoTypes from '../json/convoTypes';
import Panel from '@/components/ui/Panel';
import config from '@/config';
import WidgetSnippet from '@/components/WidgetSnippet';

export default {
    name: 'Publishing',
    components: {
        EmailBroadcast,
        ConvoUrlOverlay,
        PageHeading,
        ToggleText,
        ConvoUrl,
        Spinner,
        Stepper,
        Dialog,
        Panel,
        ContentDiscovery,
        ErrorMessage,
        WidgetSnippet,
    },
    data() {
        return {
            LoadingFlag,
            breadcrumbs: [],
            audiences: [],
            showBroadcastDialog: false,
            isBroadcasting: false,
            broadcastData: null,
            broadcast: 'local-timezone',
            showConvoUrlOverlay: false,
            convoUrl: null,
            tenantData: {},
            showCommentsRatingError: false,
            chatHeadline: false,
            unreadBadge: false,
        };
    },
    computed: {
        ...mapState('convoDetails', {
            convo: 'convo',
            publishing: 'publishing',
        }),
        ...mapGetters('main', {
            isLoading: 'isLoading',
        }),
        ...mapGetters('channel', {
            channelDetails: 'channelDetails',
        }),
        ...mapGetters('convoDetails', {
            convoDetailsView: 'convoDetailsView',
        }),
        widgetSnippet() {
            return getWidgetSnippet({
                appKey: this.channelDetails.tenantData.appKey,
                appSecret: this.channelDetails.tenantData.appSecret,
                appCode: this.channelDetails.tenantData.appCode,
                channel: this.channelDetails.channel.slug,
                convo: this.convo.id,
                showUnreadBadge: this.showUnreadBadge,
                showChatHeadline: this.showChatHeadline,
            });
        },
        showWidgetSnippet() {
            return (
                Object.keys(this.channelDetails.tenantData).length &&
                config.featureToggle.widgetSnippet
            );
        },
        disableEmailBroadcast() {
            return !this.convoDetailsView.convo.published || this.publishing.isPollingEmailCampaign;
        },
        warningMessage() {
            const keys = Object.keys(this.convo.warnings) || [];
            let message = '';
            if (keys.length > 0) {
                message = this.convo.warnings[keys[0]][0];
            }
            return message;
        },
        convoType() {
            const convoType = convoTypes.find((t) => t.name === this.convoDetailsView.convo.type);
            if (convoType) {
                return convoType.title;
            }
            return 'Convo';
        },
        convoBaseUrl() {
            return `${config.baseUrl}/channels/${this.channelDetails.channel.slug}/${this.convoDetailsView.convo.id}`;
        },
        pageIsLoading() {
            return (
                this.$wait.is(LoadingFlag.ConvoGetOne) ||
                this.$wait.is(LoadingFlag.MessagesGet) ||
                this.convoDetailsView.convo.id === 0
            );
        },
        discovery: {
            get() {
                const { showFromDate, hideFromDate } = this.publishing;
                return {
                    showDate: showFromDate,
                    hideDate: hideFromDate,
                };
            },
            set(value) {
                this.setPublishingProperty(value);
            },
        },
        showUnreadBadge() {
            return this.unreadBadge;
        },
        showChatHeadline() {
            return this.chatHeadline;
        },
    },
    async created() {
        await this.setChannelDetails();
        await this.loadConvo({
            id: this.$route.params.convoId,
            channelCode: this.channelDetails.channel.code,
        });
        await this.validateConvo(ConvoOperation.Publishing);
        this.getChannelTenant(this.channelDetails.channel.id);
        this.pollConvoEmailCampaignStatus(this.$route.params.convoId);
        this.getAudiences();
        this.setBreadcrumbs();
    },
    destroyed() {
        this.resetConvoDetailsState();
    },
    methods: {
        ...mapActions('convoDetails', {
            loadConvo: 'loadConvo',
            publishConvo: 'publish',
            setPublishingProperty: 'setPublishingProperty',
            validatePublishing: 'validatePublishing',
            resetConvoDetailsState: 'resetConvoDetailsState',
            pollConvoEmailCampaignStatus: 'pollConvoEmailCampaignStatus',
            validateConvo: 'validateConvo',
        }),
        ...mapActions('channel', {
            setChannelDetails: 'setChannelDetails',
            getChannelTenant: 'getChannelTenant',
        }),
        async getAudiences(query = '') {
            const request = new GetAudienceRequest();
            request.page = 1;
            request.size = 50;
            if (query) {
                request.q = query;
            }
            const result = await AudienceClient.getAudiences(request);
            if (result && result.items) {
                this.audiences = result.items.map((audience) => ({
                    text: audience.name,
                    id: audience.id,
                }));
            }
        },
        async handleTestBroadcast(data) {
            const request = EmailBroadcastFactory.make({
                convoId: this.$route.params.convoId.toString(),
                channelCode: this.$route.params.channelCode,
                isTest: true,
                ...data,
            });
            await AudienceClient.sendBroadcastEmail(request);
            this.pollConvoEmailCampaignStatus(this.$route.params.convoId);
            this.$toaster.add('Test email campaign processing...', { type: 'info' });
        },
        confirmSendBroadcast(broadcastData) {
            this.broadcastData = broadcastData;
            this.showBroadcastDialog = true;
        },
        async confirmBroadcastDialog() {
            this.isBroadcasting = true;
            const request = EmailBroadcastFactory.make({
                convoId: this.$route.params.convoId.toString(),
                channelCode: this.$route.params.channelCode,
                ...this.broadcastData,
            });
            await AudienceClient.sendBroadcastEmail(request);
            this.pollConvoEmailCampaignStatus(this.$route.params.convoId);
            this.showBroadcastDialog = false;
            this.isBroadcasting = false;
            this.broadcastData = null;
            this.$toaster.add('Email campaign processing...', { type: 'info' });
        },
        closeBroadcastDialog() {
            if (this.isBroadcasting) {
                return;
            }
            this.showBroadcastDialog = false;
            this.broadcastData = null;
        },
        setBreadcrumbs() {
            const breadcrumbs = [];
            breadcrumbs.push(new Breadcrumb('channels', { name: 'all-channels' }));
            let convoLink;
            if (this.channelDetails.channel.code) {
                convoLink = {
                    name: 'convo-list',
                    params: { channelCode: this.channelDetails.channel.code },
                };
            }
            breadcrumbs.push(new Breadcrumb(this.channelDetails.channel.name, convoLink));
            breadcrumbs.push(
                new Breadcrumb(`${this.convoType} CONVO: ${this.convoDetailsView.convo.name}`, {
                    name: 'messages',
                    channelCode: this.channelDetails.channel.code,
                    convoId: this.convoDetailsView.convo.id,
                }),
            );
            breadcrumbs.push(new Breadcrumb('Publish'));
            this.breadcrumbs = breadcrumbs;
        },
        backButtonClick() {
            this.$router.push({
                name: 'messages',
                params: { channelCode: this.channelDetails.channel.code },
            });
        },
        async addButtonOnClick() {
            await this.validatePublishing();

            if (this.convoDetailsView.convo.errors?.commentsRating?.length) {
                this.showCommentsRatingError = true;
                return;
            }

            if (!(this.publishing.errors && Object.keys(this.publishing.errors).length)) {
                await this.publishConvo();
                this.resetConvoDetailsState();
                this.$router.push({
                    name: 'convo-list',
                    params: { channelCode: this.channelDetails.channel.code },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.publishing-spinner {
    @include center();
    position: absolute;
}

.section-container {
    margin-bottom: 35px;

    &--2-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
    }

    &--3-columns {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }
}

.panel {
    em {
        font-size: 12px;
        font-style: italic;
    }

    strong {
        font-weight: $font-weight-bold;
    }
}

.date-time {
    display: flex;

    .datepicker {
        padding-right: 5px;
    }

    .timepicker {
        padding-left: 5px;
    }
}

.date-time-clear-button-wrapper {
    text-align: right;
}

.fade-enter-active {
    animation: fade-in 0.3s;
}

.fade-leave-active {
    animation: fade-in 0.3s reverse;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
