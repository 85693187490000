<template>
    <Panel title="URL Builder">
        <h4 class="m-b-2">Tenant</h4>
        <Input
            id="base-url"
            :value="baseUrl"
            name="base-url"
            type="text"
            label="Domain"
            required
            disabled
        />

        <h4 class="m-b-2">Subscription Action</h4>
        <Select
            id="action"
            :value="formData.action"
            name="action"
            placeholder="Select a Subscription Action..."
            label="Subscription Action"
            :options="subscriptionActions"
            @input="(value) => handleInput('action', value)"
        />

        <h4 class="m-b-2">Campaign Parameters</h4>
        <Input
            id="utm-source"
            :value="formData.utm_source"
            placeholder="Enter text here"
            name="utm-source"
            type="text"
            label="Campaign Source"
            info-text="Note: no spaces allowed"
            required
            @input="(value) => handleInput('utm_source', value)"
        />

        <Input
            id="utm-medium"
            :value="formData.utm_medium"
            placeholder="Enter text here"
            name="utm-medium"
            type="text"
            label="Campaign Medium"
            info-text="Note: no spaces allowed"
            required
            @input="(value) => handleInput('utm_medium', value)"
        />

        <Input
            id="utm-campaign"
            :value="formData.utm_campaign"
            placeholder="Enter text here"
            name="utm-campaign"
            type="text"
            label="Campaign Name"
            info-text="Note: no spaces allowed"
            required
            @input="(value) => handleInput('utm_campaign', value)"
        />

        <Input
            id="utm-contentall"
            :value="formData.utm_content"
            placeholder="Enter text here"
            name="utm-contentall"
            type="text"
            label="Campaign Content"
            info-text="Note: no spaces allowed"
            required
            @input="(value) => handleInput('utm_content', value)"
        />

        <Toggle
            id="recontact-id"
            label="Add Recontact Id"
            :value="false"
            @input="(value) => handleInput('recontact_id', value)"
        />

        <div class="text-right">
            <Button button-style="primary" size="small" right-aligned-text @click="$emit('clear')"
                >Clear fields</Button
            >
        </div>
    </Panel>
</template>

<script>
import Select from '@/components/forms/Select';
import Input from '@/components/forms/Input';
import Button from '@/components/ui/Button';
import Panel from '@/components/ui/Panel';
import Toggle from '@/components/forms/Toggle';

const actions = [
    { id: '', value: '---' },
    { id: 'subscribe', value: 'Subscribe' },
    { id: 'signup', value: 'Register' },
    { id: 'no-signup', value: 'Free Convo' },
    { id: 'landing', value: 'Landing Page (Email)' },
    { id: 'landing-sms', value: 'Landing Page (SMS)' },
];

export default {
    name: 'ConvoUrlBuilder',
    components: {
        Select,
        Input,
        Button,
        Panel,
        Toggle,
    },
    props: {
        baseUrl: {
            type: String,
            required: true,
        },
        formData: {
            type: Object,
            required: true,
        },
    },
    computed: {
        subscriptionActions() {
            return actions;
        },
    },
    methods: {
        handleInput(key, value) {
            const formData = { ...this.formData };
            if (key === 'recontact_id') {
                if (value) {
                    formData[key] = '{{user_id}}';
                } else {
                    delete formData['recontact_id'];
                }
            } else {
                formData[key] = value;
            }
            this.$emit('changed', formData);
        },
    },
};
</script>
