import { EmailSubjectType } from '@/enums';
import { EmailBroadcastRequest } from '../dtos/emailBroadcastRequest.dto';

interface IBroadcastData {
    emailSubjectType: EmailSubjectType;
    emailCustomSubjectLine: string;
    convoId: string | number;
    followUpEnabled: boolean;
    followUpInterval: number;
    followUpEmailSubjectType: EmailSubjectType;
    followUpEmailCustomSubjectLine: string;
    selectedAudience?: { id: number; text: string };
    isTest?: boolean;
    channelCode?: string;
}
export class EmailBroadcastFactory {
    public static make(data: IBroadcastData): EmailBroadcastRequest {
        const request = new EmailBroadcastRequest();

        request.emailSubjectType = data.emailSubjectType;
        request.serviceEmailCustomSubjectLine = data.emailCustomSubjectLine;
        request.isTest = !!data.isTest;
        request.convoId = `${data.convoId}`;
        request.enableFollowUp = data.followUpEnabled;

        if (data.followUpEnabled) {
            request.followUpAfterDays = `${data.followUpInterval}`;
            request.followUpEmailSubjectType = data.followUpEmailSubjectType;
            request.followUpCustomSubjectLine = data.followUpEmailCustomSubjectLine;
        }

        if (data.channelCode) {
            request.channelCode = data.channelCode;
        }

        if (data.selectedAudience) {
            request.audienceId = `${data.selectedAudience.id}`;
        }
        return request;
    }
}
