<template>
    <div class="widget-snippet">
        <div class="toggle-group">
            <Toggle
                id="unread-chat-toggle"
                :value="showUnreadBadge"
                name="unread-chat-toggle"
                class="unread-chat-toggle"
                label="Unread Chat Bubble"
                @change="$emit('unreadChatToggle', !showUnreadBadge)"
            />

            <Toggle
                id="floating-headline-toggle"
                :value="showChatHeadline"
                name="floating-headline-toggle"
                class="floating-headline-toggle"
                label="Floating Headline"
                @change="$emit('floatingHeadlineToggle', !showChatHeadline)"
            />
        </div>
        <CopyText name="widget-snippet" label="Widget HTML embed" :value="snippet" />
        <div class="copy-button-group">
            <Button
                button-style="primary"
                has-border
                size="small"
                @click="copyHTMLOnClick(snippet)"
            >
                Copy HTML
            </Button>
            <div class="copied-label-wrapper">
                <transition name="fade">
                    <div v-if="showCopiedLabel" class="copied-label">Copied to clipboard!</div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import Toggle from '@/components/forms/Toggle';
import { CopyText } from '@/components/forms';
import Button from '@/components/ui/Button';

export default {
    name: 'WidgetSnippet',
    components: {
        Toggle,
        CopyText,
        Button,
    },
    props: {
        snippet: {
            type: String,
            required: true,
        },
        showUnreadBadge: {
            type: Boolean,
            default: false,
        },
        showChatHeadline: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showCopiedLabel: false,
        };
    },
    methods: {
        copyHTMLOnClick(widgetSnippet) {
            const tempInput = document.createElement('input');
            tempInput.value = widgetSnippet;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);

            this.showCopiedLabel = true;
            setTimeout(() => {
                this.showCopiedLabel = false;
            }, 2000);
        },
    },
};
</script>

<style lang="scss" scoped>
.toggle-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.unread-chat-toggle {
    display: flex;
    order: 1;
    width: 50%;
}

.floating-headline-toggle {
    display: flex;
    order: 2;
    width: 50%;
}

.copy-button-group {
    display: flex;
    justify-content: center;
}

.copied-label-wrapper {
    position: relative;
    height: 30px;

    &::before {
        content: '\00a0';
    }
}

.copied-label {
    @include center(y);
    position: absolute;
    top: 50%;
    display: inline-block;
    padding: 5px 5px 6px;
    border-radius: 4px;
    background: $color-oxford-blue;
    font-size: 10px;
    color: $color-white;
    white-space: nowrap;
}
</style>
