<template>
    <section>
        <p class="paragraph--medium">
            Set your schedule to show and/or hide content from the YouGov.chat homepage and channel
            pages.
        </p>

        <p class="paragraph--medium">
            If you leave both fields blank, Published content will still be available via the direct
            URL or logic jump, but users will not be able to find it organically.
        </p>

        <Datepicker
            v-model="localShowDate"
            heading-text="Start showing this convo"
            heading-icon="eye-open"
            name="start-date"
            label="Date"
            class="m-b-2"
            :errors="errors.showFromDate"
        />

        <Datepicker
            v-model="localHideDate"
            heading-text="Stop showing this convo"
            heading-icon="eye-close"
            name="end-date"
            label="Date 1"
            :min="localShowDate"
            :errors="errors.hideFromDate"
        />
    </section>
</template>

<script>
import Datepicker from '@/components/DatePicker/DiscoveryDatePicker';

export default {
    name: 'ContentDiscovery',
    components: {
        Datepicker,
    },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        localShowDate: {
            get() {
                return this.value.showDate;
            },
            set(value) {
                this.$emit('input', { key: 'showFromDate', value });
            },
        },
        localHideDate: {
            get() {
                return this.value.hideDate;
            },
            set(value) {
                this.$emit('input', { key: 'hideFromDate', value });
            },
        },
    },
};
</script>
