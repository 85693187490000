<template>
    <div class="ddp-container" :class="{ 'ddp-container--error': hasErrors }">
        <div class="ddp-heading m-b-1">
            <Icon v-if="headingIcon !== ''" :name="headingIcon" class="m-r-1" />

            <h4>{{ headingText }}</h4>
        </div>

        <div class="ddp-content">
            <Datepicker
                :id="`${name}-date-picker`"
                label="Date"
                :placeholder="placeholder"
                class="ddp-date m-r-1"
                :has-margin-bottom="false"
                :name="name"
                :value="date"
                @change="date = $event"
            />

            <Timepicker
                :id="`${name}-time-picker`"
                :name="name"
                :value="time"
                label="Time"
                class="m-r-1"
                @change="time = $event"
            />

            <Button
                class="ddp-button ddp-now"
                button-style="secondary"
                title="now"
                @click="$emit('input', new Date())"
            >
                Now
            </Button>

            <div class="ddp-separator"></div>

            <Button
                class="ddp-button ddp-clear"
                button-style="secondary"
                title="clear"
                @click="$emit('input', null)"
            >
                Clear
            </Button>
        </div>

        <div v-if="hasErrors" class="form-field-error-message">
            <Icon name="alert" />
            {{ errors[0] }}
        </div>
    </div>
</template>

<script>
import { Datepicker, Timepicker } from '@/components/forms';
import Button from '@/components/ui/Button';
import { addLeadingZero } from '@/utils';
import Icon from '@/components/ui/Icon';
import { set } from 'date-fns';

export default {
    name: 'DiscoveryDatePicker',
    components: {
        Timepicker,
        Datepicker,
        Button,
        Icon,
    },
    props: {
        headingText: {
            type: String,
            required: true,
        },
        headingIcon: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: Date,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: 'Select date',
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        time: {
            get() {
                if (!this.value) {
                    return null;
                }
                return this.getTimeFromDate(this.value);
            },
            set(time) {
                const [hours, minutes, seconds] = time.split(':');
                const newDate = set(this.getDate(), { hours, minutes, seconds });
                this.handleInput(newDate);
            },
        },
        date: {
            get() {
                return this.value;
            },
            set(date) {
                const newDate = set(this.getDate(), {
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    date: date.getDate(),
                });
                this.handleInput(newDate);
            },
        },
        hasErrors() {
            return this.errors.length;
        },
    },
    methods: {
        handleInput(value) {
            this.$emit('input', value);
        },
        getTimeFromDate(date) {
            const hours = addLeadingZero(date.getHours());
            const minutes = addLeadingZero(date.getMinutes());
            const seconds = addLeadingZero(date.getSeconds());
            return `${hours}:${minutes}:${seconds}`;
        },
        getDate() {
            if (this.value && typeof this.value.getMonth === 'function') {
                return this.value;
            }
            return new Date();
        },
    },
};
</script>

<style lang="scss" scoped>
.ddp-container {
    &--error {
        /deep/ .form-field {
            border-color: $error-color;
        }

        /deep/ .form-field-label {
            color: $error-color;
        }
    }

    .form-field-error-message {
        margin-top: 4px;
    }
}

.ddp-date {
    width: 40%;
    flex-shrink: 0;
}
.ddp-time {
    width: 20%;
}
.ddp-button {
    padding: 0 4px;
    font-size: 10px;
    min-width: auto;
}
.ddp-heading,
.ddp-content {
    display: flex;
    align-items: center;
}

.ddp-heading {
    h4 {
        font-size: 15px;
        font-weight: bold;
    }
}
.ddp-separator {
    margin: 0 5px;
    background-color: $input-border-color;
    width: 1px;
    height: 20px;
    flex-shrink: 0;
}
</style>
