<template>
    <Panel title="Convo URL">
        <div class="convo-url m-b-1">
            <Icon name="url" class="convo-url__icon" />

            <div class="convo-url__url">{{ url }}</div>

            <div class="convo-url__copied-label-wrapper">
                <transition name="fade">
                    <div v-if="showCopiedLabel" class="convo-url__copied-label">
                        Copied to clipboard!
                    </div>
                </transition>
            </div>
        </div>

        <div class="text-center">
            <Button
                class="convo-url__button"
                button-style="primary"
                has-border
                size="small"
                @click="copyUrlOnClick(url)"
            >
                Copy Url
            </Button>

            <Button
                v-if="showConfigureButton"
                button-style="secondary"
                class="convo-url__configure"
                @click="$emit('configure')"
            >
                Configure
            </Button>
        </div>
    </Panel>
</template>

<script>
import Button from '@/components/ui/Button';
import Panel from '@/components/ui/Panel';
import Icon from '@/components/ui/Icon';

export default {
    name: 'ConvoUrl',
    components: {
        Button,
        Panel,
        Icon,
    },
    props: {
        url: {
            type: String,
            required: true,
        },
        showConfigureButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showCopiedLabel: false,
        };
    },
    methods: {
        copyUrlOnClick(url) {
            const tempInput = document.createElement('input');
            tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
            tempInput.value = url;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);

            this.showCopiedLabel = true;
            setTimeout(() => {
                this.showCopiedLabel = false;
            }, 2000);
        },
    },
};
</script>

<style lang="scss" scoped>
.convo-url {
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 2px;
        margin-left: -9px; // Compensate for icon padding
        flex: 0 0 auto;
        font-size: 42px;
        color: $primary-color;
    }

    &__url {
        flex-grow: 1;
        font-size: 14px;
        max-width: 100%;
        min-width: 0;
        padding-right: 5px;
        word-break: break-all;
    }

    &__button {
        flex: 0 0 auto;
    }

    &__copied-label-wrapper {
        position: relative;
        height: 30px;

        &::before {
            content: '\00a0';
        }
    }

    &__copied-label {
        @include center(y);
        position: absolute;
        top: 50%;
        right: 12px;
        display: inline-block;
        padding: 5px 5px 6px;
        border-radius: 4px;
        background: #30394a;
        font-size: 10px;
        color: #fff;
        white-space: nowrap;
    }
}
</style>
