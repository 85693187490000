<template>
    <TemplateVariables
        :sorting-order="sortingOrder"
        :variable-list="variableList"
        :variable-values="variableValues"
        :value="value"
        class="custom-subject-line"
        :class="{ 'custom-subject-line--error': !isValid }"
        @sortingOrder="$emit('sortingOrder', $event)"
        @input="$emit('input', $event)"
        @interpolated="onInterpolated"
    >
        <template v-slot:default="{ inputEvents, htmlValue }">
            <ContentEditable
                :id="id"
                :type="type"
                :label="label"
                :value="htmlValue"
                :placeholder="placeholder"
                :info-text="infoText"
                class="custom-subject-line-editable"
                @mouseup="inputEvents.mouseup"
                @keypress="inputEvents.keypress"
                @keyup="inputEvents.keyup"
                @input="inputEvents.input"
                @focus="inputEvents.focus"
                @blur="inputEvents.blur"
            />

            <TextPreview>{{ previewValue }}</TextPreview>

            <div class="custom-subject-line-info">
                <ErrorMessage v-show="!isValid" message="Text exceeds character limit" />

                <CharCounter
                    v-if="maxChars && maxChars > 0"
                    :max="maxChars"
                    :count="previewValue.length"
                    class="custom-subject-line-chars"
                />
            </div>
        </template>
    </TemplateVariables>
</template>

<script>
import ContentEditable from '@/components/forms/ContentEditable';
import CharCounter from '@/components/forms/partials/CharCounter';
import ErrorMessage from '@/components/forms/partials/ErrorMessage';
import TextPreview from '@/components/forms/TextPreview';
import TemplateVariables from '@/components/TemplateVariables';

export default {
    name: 'CustomSubjectLine',
    components: {
        TemplateVariables,
        ContentEditable,
        ErrorMessage,
        TextPreview,
        CharCounter,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        variableList: {
            type: Array,
            default: () => [],
        },
        variableValues: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: String,
            default: '',
        },
        sortingOrder: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        type: {
            type: String,
            default: 'input',
            validator: (value) => ['input', 'textarea'].includes(value),
        },
        infoText: {
            type: String,
            default: undefined,
        },
        maxChars: {
            type: Number,
            default: undefined,
        },
    },
    data() {
        return {
            model: '',
        };
    },
    computed: {
        previewValue() {
            return this.maxChars === undefined
                ? this.model
                : this.model.substring(0, this.maxChars);
        },
        isValid() {
            if (this.maxChars === undefined) {
                return true;
            }
            return this.model.length <= this.maxChars;
        },
    },
    methods: {
        onInterpolated(value) {
            this.model = value;
            this.$emit('interpolated', this.previewValue);
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-subject-line--error {
    /deep/ {
        .form-field {
            border-color: $error-color;
        }

        .form-field-label {
            color: $error-color;
        }

        .text-preview {
            background-color: tint($error-color, 90%);
        }
    }
}

.custom-subject-line-info {
    display: flex;
    align-items: center;
}

/deep/ .custom-subject-line-chars {
    margin-left: auto;
}

.custom-subject-line-editable {
    margin-bottom: 10px;
}
</style>
