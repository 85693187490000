<template>
    <FormOverlay
        title="Convo URL - Configure"
        confirm-button-text="Done"
        :show-overlay="showOverlay"
        @close="$emit('close')"
        @confirm="$emit('close')"
    >
        <div class="p-t-4">
            <ConvoUrlBuilder
                :base-url="baseUrl"
                :form-data="formData"
                class="m-b-4"
                @changed="handleChange"
                @clear="handleClear"
            />

            <ConvoUrl :url="url" />
        </div>
    </FormOverlay>
</template>

<script>
import { UrlBuilderService } from '@/store/services/urlBuilderService';
import ConvoUrlBuilder from '@/components/ConvoUrl/ConvoUrlBuilder';
import FormOverlay from '@/components/ui/FormOverlay';
import ConvoUrl from '@/components/ConvoUrl/ConvoUrl';

const defaultFormData = {
    action: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
};

export default {
    name: 'ConvoUrlOverlay',
    components: {
        ConvoUrlBuilder,
        FormOverlay,
        ConvoUrl,
    },
    props: {
        showOverlay: {
            type: Boolean,
            required: true,
        },
        baseUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            url: this.baseUrl,
            formData: defaultFormData,
        };
    },
    created() {
        this.service = new UrlBuilderService();
    },
    methods: {
        handleChange(formData) {
            try {
                this.formData = formData;
                this.service.setUrl(this.baseUrl);
                Object.keys(formData).forEach((key) => {
                    if (formData[key]) {
                        this.service.setParam(key, formData[key]);
                    }
                });
                this.url = this.service.build();
                this.$emit('changed', this.url);
            } catch (error) {
                Vue.$log.error(error);
            }
        },
        handleClear() {
            this.formData = defaultFormData;
        },
    },
};
</script>
