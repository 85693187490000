import config from '@/config';

const getSnippet = (cdnUrl: string, options: string) =>
    `(function(e,t,c,n){e[n]=e[n]||function(){(e[n]._q=e[n]._q||[]).push(arguments)};var a=t.createElement("script");a.type="text/javascript";a.async=true;a.src=c;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(a,i)})(window,document,"${cdnUrl}","ic");ic("init",${options});`;

const wrapSnippet = (snippet: string) => `<script>\n${snippet}\n</script>`;

interface IWidgetOptions {
    appCode: string;
    appKey: string;
    appSecret: string;
    channel: string;
    convo?: string;
    showUnreadBadge?: boolean;
    showChatHeadline?: boolean;
}

export const getWidgetSnippet = (options: IWidgetOptions) => {
    return wrapSnippet(getSnippet(config.cdnUrl, JSON.stringify(options)));
};

export default getWidgetSnippet;
